export default function AboutMe() {
  return (
    <div className="page">
      <header>About me</header>
      <div className="main">
        <div className="mpu-text-block">
          <p>
            I am a creative programmer from an artistic background. After 9
            professional years in JavaScript development in the advertising
            field, I am looking for my next career move as a front or full-stack
            developer where I can design and build complex and sophisticated web
            applications. In addition to my proficiency in HTML, CSS, and
            JavaScript, I am skillful in various front and backend technologies
            listed below. With a degree in Graphic Design, I was a graphic
            designer before becoming a programmer, specialising in layout and
            user interface design. My strengths in mathematics and
            problem-solving, honed both academically and professionally, enable
            me to tackle programming challenges effectively. With a diverse
            skill set and a passion for creating captivating digital
            experiences, I am eager to bring my expertise to your business.
          </p>
          <p>
            Outside of work I enjoy travelling. I have been to 653 cities in 50
            countries to date. Fine art is my other passion. I visit art museums
            every week and am myself a draughtsman. I am also a gym addict who
            visits four to five times every week. Of course all these happened
            in the good old days before Covid-19.
          </p>
          <p>
            Born in a Portuguese colony in China, I grew up in a culturally
            diverse environment and have now lived in the English capital for
            more than twenty years. My logo is an emblem of my three distinct
            cultural identities: the head of a Chinese dragon, the body of an
            English lion and the tail of a Portuguese rooster.
          </p>
        </div>
        <div className="mpu-block">
          <img src="./assets/thumbnails/samson_ng.jpg" alt="Samson Ng" />
        </div>
      </div>
    </div>
  );
}
